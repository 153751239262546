/* Footer.css */
:root {
    --footer-height: 60px; /* Adjust this value based on your footer's actual height */
  }
  
  .footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: black;
    z-index: 1000;
    padding: 10px 0;
    /* Remove the fixed height */
}

.footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 400px; /* Match the initial max-width of the training section */
    margin: 0 auto;
}

.sponsor-images {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    padding: 0 10px;
}

.sponsor-image {
    height: 30px;
    width: auto;
    object-fit: contain;
    /* Add max-height to ensure visibility */
    max-height: calc(100% - 20px); /* Subtracting padding */
}

.sponsor-title {
    display: none;
    color: white;
}

/* Adjust main content to account for footer */
.training-section.training-page {
    padding-bottom: 100px; /* Adjust this value based on your footer height */
}

@media (min-width: 768px) {
    .footer-content {
        max-width: 600px; /* Match the medium screen max-width of the training section */
    }
}

@media (min-width: 1025px) {
    .footer {
        left: 50%;
    transform: translateX(-50%);
    width: 70%; /* Match the max-width of the juniors-section */
    max-width: calc(100% - 40px); /* Account for padding */
    }
    
    .footer-content {
        max-width: 800px;
    }

    .sponsor-title {
        display: block;
        font-size: 1rem;
        margin-bottom: 5px;
        text-align: center;
    }

    .sponsor-images {
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 15px;
        width: 100%;
    }

    .sponsor-image {
        height: 40px;
    }
}