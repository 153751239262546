/* Navbar.css */

.nav-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 0px;
    position: fixed;
    width: 100%;
    background-color: #000000;
    height: 60px;
    top: 0;
    z-index: 1001;
  }
  
  .logo-title {
    display: flex;
    align-items: center;
    padding-top: 0px;
    margin-left: 4%;
  }
  
  .logo {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }
  
  .title-container {
    width: 191.017px;
    height: 36px;
    font-weight: bold;
    display: flex;
    align-items: center;
    overflow: hidden;
  }
  
  .title-container h1 {
    font-size: 24px;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .icon-burger {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 4%;
    top: 40%;
    margin-top: 5.5px;
    transform: translateY(-50%);
    cursor: pointer;
  }
  
  .icon-burger .line {
    width: 30px;
    height: 5px;
    background-color: #fff;
    margin: 3px;
    border-radius: 3px;
    transition: all 0.3s ease-in-out;
  }
  
  .icon-burger.active .line:nth-child(1) {
    transform: rotate(45deg);
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -2.5px;
    margin-left: -15px;
  }
  
  .icon-burger.active .line:nth-child(2) {
    opacity: 0;
  }
  
  .icon-burger.active .line:nth-child(3) {
    transform: rotate(-45deg);
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -2.5px;
    margin-left: -15px;
  }
  
  .sub-menus {
    position: fixed;
    top: 60px;
    left: 0;
    right: 0;
    background-color: #000000;
    text-align: center;
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.5s ease-in-out;
    z-index: 1000;
  }
  
  .sub-menus.active {
    max-height: 100vh;
  }
  
  .sub-menus ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .sub-menus ul li {
    padding: 10px;
  }
  
  .sub-menus ul li a {
    text-decoration: none;
    color: #fff;
    font-family: "Oswald", sans-serif;
    transition: color 0.3s ease;
  }
  
  .sub-menus ul li a:hover {
    color: #2e8b57 !important;
  }
  
  .nav-bar-medium,
  .home-club-medium {
    display: none;
  }
  
  @media screen and (min-width: 768px) {
    .nav-bar-medium {
      display: flex;
      justify-content: center;
      padding: 10px;
      position: sticky;
      top: 0;
      z-index: 1000;
      background-color: black;
    }
  
    .nav-bar-medium ul {
      display: flex;
      font-size: larger;
      justify-content: space-evenly;
      list-style: none;
      padding: 0;
      margin: 0;
      width: 100%;
    }
  
    .nav-bar-medium a {
      color: white;
      text-decoration: none;
      transition: color 0.3s ease;
    }
  
    .nav-bar-medium a:hover {
      color: seagreen;
    }
  
     .home-club-medium {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    position: absolute;
    top: 12%;
    left: 20.25%;
    z-index: 10;
  }

  .home-image-medium {
    width: 200px;
    height: auto;
  }

  .home-club-medium .title-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-left: 10px;
    width: auto;
    height: auto;
  }

  .home-club-medium h1 {
    font-size: 35px;
    line-height: 1;
    margin: 0;
    padding: 0;
    margin-bottom: -15px;
    overflow: visible;
    text-overflow: clip;
    white-space: nowrap;
  }

  .home-club-medium h2 {
    font-size: 30px;
    margin-bottom: 0px;
  }
}

@media (min-width: 1025px) {
  .nav-bar, .nav-bar-medium {
    position: sticky;
    top: 0;
    /* other styles */
  }
}

  @media screen and (max-width: 1600px) {
    .home-image-medium {
      width: 140px;
    }
  
    .home-club-medium h1 {
      font-size: 32px;
    }
  
    .home-club-medium h2 {
      font-size: 27px;
    }

    .home-content .home-title {
      font-size: 2.5rem !important;
    }
  
    .home-content .home-paragraph {
      font-size: 1rem !important;
    }
  }
  
  @media screen and (max-width: 1200px) {
    .home-club-medium {
      left: 10%;
    }
  
    .home-image-medium {
      width: 160px;
    }
  
    .home-club-medium h1 {
      font-size: 28px;
    }
  
    .home-club-medium h2 {
      font-size: 24px;
    }
  }
  
  @media screen and (max-width: 1024px) {
    .home-club-medium {
      left: 5%;
    }
  
    .home-image-medium {
      width: 140px;
    }
  
    .home-club-medium h1 {
      font-size: 24px;
    }
  
    .home-club-medium h2 {
      font-size: 20px;
    }
  }
  
  @media screen and (max-width: 900px) {
    .home-club-medium {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .home-image-medium {
      width: 120px;
      margin-bottom: 10px;
    }
  
    .home-club-medium .title-container {
      margin-left: 0;
    }
  }
  
  
  /* Add more media queries as needed for different device sizes */