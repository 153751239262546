@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Oswald', sans-serif;
  background: url('./Images/Team_2023_Tampere_3.png') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  color: white;
}

#root {
  min-height: 100vh;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.content-container {
  flex-grow: 1;
  background-color: black;
  width: 100%;
}

@media (min-width: 1025px) {
  .content-container {
    width: 70%;
    margin: 0 auto;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
}